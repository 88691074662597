#team {
  .section-title {
    margin: 0 24px 24px;
  }
  .team-image-wrapper {
    width: calc(100% - 48px);
    padding-top: 45%;
    background-image: url('../images/team/team.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 24px;
  }
  .dna-wrapper {
    display: flex;
    flex-direction: column;
    .dna-left {
      color: $primary;
      font-size: 20px;
      text-align: center;
      line-height: 26px;
      margin: 24px 12px 12px;
    }
    .dna-right {
      color: $greyOne;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin: 0 24px 24px;
    }
  }
  .founders-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    .items-wrapper {
      flex-direction: column;
      .item {
        width: auto;
        margin: 0 0 24px;
        flex-grow: 0;
        .item-content {
          h5 {
            margin: 0;
          }
          h6 {
            margin: 0 0 6px;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .items-wrapper {
    .item {
      .image {
        width: 100%;
        padding-top: 65%;
        background-size: cover;
        background-position: center;
        &.dig {
          background-image: url('../images/DIG.png');
        }
      }
      .item-content {
        h5 {
          margin: 0;
        }
        h6 {
          margin: 0 0 6px;
        }
        .social-links {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          a {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .content-break {
    margin: 48px auto;
  }
  @media screen and (min-width: 768px) {
    .section-title {
      margin: 0 24px;
    }
    .dna-wrapper {
      flex-direction: row;
      margin: 48px 0;
      .dna-left {
        width: 33%;
        font-size: 28px;
        line-height: 34px;
        flex-shrink: 0;
        text-align: left;
        margin: 0 0 0 24px;
      }
      .dna-right {
        flex-grow: 1;
        font-size: 20px;
        line-height: 26px;
        text-align: left;
        margin: 0 24px 0 48px;
      }
    }
    .founders-wrapper {
      margin: 0 0 24px;
      .founders-image {
        min-width: 29%;
        height: 575px;
        background-size: cover;
        background-position: top center;
        margin-left: 24px;
      }
      .items-wrapper {
        margin: 0 24px;
        .item {
          display: flex;
          flex-direction: row;
          max-width: none;
          .image {
            width: 200px;
            height: 200px;
            padding-top: 0;
            flex-shrink: 0;
          }
          .item-content {
            padding: 0 0 0 24px;
            p {
              margin: 0 0 6px;
            }
          }
        }
      }
    }
    .items-wrapper {
      .item {
        width: 29%;
        max-width: 29%;
        .image {
          &.dig {
            &:hover {
              background-image: url('../images/pushAllButtons.gif');
            }
          }
        }
        .item-content {
          h5 {
            margin: 0;
            //margin: 0 0 12px;
          }
          h6 {
            margin: 0 0 12px;
          }
        }
      }
    }
    .content-break {
      margin: 48px auto 60px;
    }
  }
  @media screen and (min-width: 1024px) {
    .dna-wrapper {
      .dna-left {
        width: 29%;
        font-size: 24px;
      }
    }
    .founders-wrapper {
      flex-direction: row;
      .items-wrapper {
        justify-content: center;
        margin: 0 24px 0 48px;
        .item {
          margin: 0 0 12px;
          .item-content {
            padding: 0;
            h6 {
              margin: 0;
            }
            p {
              margin: 0;
            }
            .social-links {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1280px) {
    .dna-wrapper {
      .dna-left {
        font-size: 28px;
        width: 378px;
      }
    }
    .founders-wrapper {
      margin: 0 24px 24px;
      .founders-image {
        min-width: 378px;
        margin: 0;
      }
      .items-wrapper {
        margin: 0 0 0 48px;
        .item {
          margin: 0 0 0px;
          .item-content {
            h6 {
              margin: 0 0 12px;
            }
          }
        }
      }
    }
  }
}
