#careers {
  ul {
    list-style-type: none;
    margin-bottom: 70px;
    margin-top: 20px;
    margin-right: 24px;
    text-align: center;
  }
  .whr-info {
    display: none;
  }
  @media screen and (min-width: 768px) {
    ul {
      text-align: center;
      margin-top: 0;
    }
  }
}
