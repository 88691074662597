// footer styles
#footer-wrapper {
  background-color: $footerBackground;
  background-image: url("/images/wiggle_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  .inreach-logo-row {
    margin-top: 24px;
    img {
      margin-bottom: 0;
      width: 80px;
      border-radius: 3px;
      border: 5px solid #ffffff;
      box-shadow: 0 2px 2px rgba(0,0,0,.05);
    }
  }
  .social-links {
    display: flex;
    flex-direction: row;
    margin: 24px 0;
    .social-link {
      width: 20%;
      text-align: center;
      font-size: 20px;
      span {
        color: #ffffff;
      }
    }
  }
  .footer-text {
    text-align: center;
    color: #ffffff;
    margin-bottom: 24px;
    line-height: 16px;
    font-size: 11px;
    a {
      color: #ffffff;
      text-decoration: underline;
      &:hover {
        color: #ffffff !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .social-links-wrapper {
      margin: 24px 0;
    }
  }
}
