.icon {
  &.twitter {
    &:before {
      @include fa-icon('twitter');
    }
    &:hover {
      color: $twitter !important;
    }
  }
  &.facebook {
    &:before {
      @include fa-icon('facebook');
    }
    &:hover {
      color: $facebook !important;
    }
  }
  &.linkedin {
    &:before {
      @include fa-icon('linkedin');
    }
    &:hover {
      color: $linkedin !important;
    }
  }
  &.github {
    &:before {
      @include fa-icon('github');
    }
    &:hover {
      color: $github !important;
    }
  }
  &.angelist {
    &:before {
      @include fa-icon('angellist');
    }
    &:hover {
      color: $angelist !important;
    }
  }
  &.medium {
    &:before {
      @include fa-icon('medium');
    }
    &:hover {
      color: $medium !important;
    }
  }
}
