#news {
  .items-wrapper {
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      .image {
        width: 100%;
        padding-top: 60%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item-content {
        padding: 24px 12px 0;
        display: flex;
        flex-direction: column;
        h5 {
          margin: 0 0 6px;
          &:hover {
            color: $secondary;
          }
        }
        h6 {
          margin: 0 0 6px;
        }
        p {
          margin: 0 0 24px;
        }
        .article-footer {
          display: flex;
          flex-direction: column;
          span {
            color: $greyOne;
            order: 2;
          }
          a {
            order: 1;
            .button {
              margin: 0 0 12px;
            }
          }
        }
      }
    }
    .related-articles {
      margin: 12px 36px 0;
      color: $greyOne;
    }
  }
  .content-break {
    margin: 48px auto;
  }
  @media screen and (min-width: 768px) {
    .items-wrapper {
      flex-direction: row;
      .item {
        width: 35%;
      }
      .related-articles {
        display: none;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .items-wrapper {
      flex-direction: column;
      .item {
        flex-direction: row;
        width: auto;
        margin: 24px 24px 60px;
        .image {
          width: 200px;
          height: 200px;
          padding-top: 0;
          flex-shrink: 0;
        }
        .item-content {
          padding: 0 24px;
          p {
            flex-grow: 1;
            margin: 0;
          }
          .article-footer {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            span {
              order: 1;
            }
            a {
              order: 2;
              .button {
                width: 200px;
                margin: 0;
              }
            }
          }
        }
      }
      .related-articles {
        display: block;
        margin: -34px 24px 12px;
      }
    }
  }
}
