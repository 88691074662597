#hero {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  #hero-content-wrapper {
    width: 768px;
    height: 358px;
    align-self: center;
    margin: auto;
    #title-wrapper {
      text-align: center;
      margin-bottom: 36px;
      h1 {
        margin: 0 24px 12px;
        padding: 0;
      }
      h2 {
        margin: 0 auto;
        padding: 0;
        max-width: 220px;
      }
    }
  }
  #get-funded-wrapper {
    text-align: center;
    margin-top: 36px;
    h3 {
      margin: 0 24px 12px;
      padding: 0;
    }
    h4 {
      margin: 0 24px;
      padding: 0;
    }
    .button {
      width: calc(100% - 48px);
      margin: 48px 24px 0;
    }
  }
  #scroll-hero {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: $primary;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .scroll-down-icon {
      color: #ffffff;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      &:before {
        @include fa-icon('chevron-down')
      }
    }
  }
  &::after {
    content: "";
    background-image: url('/images/map_temp_35_mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  @media screen and (min-width: 768px) {
    #hero-content-wrapper {
      #title-wrapper {
        margin-bottom: 48px;
        h2 {
          max-width: none;
        }
      }
    }
    #get-funded-wrapper {
      margin-top: 48px;
      .button {
        width: 280px;
        margin: 48px auto 0;
      }
    }
    #scroll-hero {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      bottom: 48px;
      .scroll-down-icon {
        font-size: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
    &::after {
      background-image: url('/images/map_temp_35.png');
      background-position: left center;
    }
  }
}
