#what-we-do {
  background-color: $greyOne;
  background-image: url("/images/wiggle_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .section {
    padding: 48px 0;
    .item {
      margin: 0 24px;
      .item-content {
        padding: 0 12px;
        h3 {
          margin: 0 0 12px;
          color: #ffffff;
          text-align: center;
        }
        p {
          color: #ffffff;
          margin: 0;
          text-align: center;
        }
      }
    }
    .button {
      margin: 36px 24px 0;
    }
  }
  @media screen and (min-width: 768px) {
    .section {
      .item {
        width: 200px;
        .content-break {
          margin: 24px auto;
        }
        p {
          font-size: 20px;
        }
      }
      .button {
        width: 280px;
        margin: 36px auto 0;
      }
    }
  }
}
