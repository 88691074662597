#navigation-bar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  z-index: 2;
  background-color: transparent;
  transition: background-color 500ms ease-in-out;
  #company-logo {
    width: 92px;
    height: 36px;
    background-image: url(/images/01_InReach_Logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
  }
  .spacer {
    flex-grow: 1;
  }
  #burger-menu-icon {
    display: block;
    width: 36px;
    height: 36px;
    padding: 11px 6px;
    .burger-menu-line {
      width: 24px;
      height: 2px;
      background-color: $primary;
      border-radius: 2px;
      &:nth-child(2) {
        margin: 4px 0;
      }
    }
  }
  #burger-menu-close {
    display: none;
    width: 36px;
    height: 36px;
    padding: 6px;
    position: relative;
    .burger-menu-line {
      position: absolute;
      left: 6px;
      top: 17px;
      width: 24px;
      height: 2px;
      background-color: $primary;
      border-radius: 2px;
      transform-origin: 50% 50%;
      &:nth-child(1) {
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
    }
  }
  #navigation-bar-links-wrapper {
    display: none;
  }
  &.open {
    background-color: white;
    #burger-menu-icon {
      display: none;
    }
    #burger-menu-close {
      display: block;
    }
    box-shadow: none !important;
  }
  &.slide-out {
    position: fixed;
    top: -60px;
    background-color: #ffffff;
    &.animate-top {
      transition: top 200ms ease-in-out;
      &.slide-in {
        top: 0;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
}
.navigation-bar-links {
  display: flex;
  flex-direction: column;
  li {
    list-style: none;
    height: 60px;
    padding: 0 24px;
    line-height: 60px;
    a {
      display: block;
      font-size: 16px;
    }
    &:nth-child(odd) {
      background-color: $greyThree;
    }
    &:nth-child(even) {
      background-color: white;
    }
    &.get-funded-button {
      margin-top: 18px;
      a {
        padding: 6px 0;
        .button {
          height: 48px;
          line-height: 46px;
        }
      }
      background-color: white;
    }
  }
}
#navigation-bar-dropdown {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  height: 0;
  z-index: 1;
  background-color: #ffffff;
  overflow: hidden;
  transition: height 500ms ease-in-out;
  #navigation-bar-dropdown-links-wrapper {
    .navigation-bar-links {
      margin: 0;
      padding: 0 !important;
    }
  }
  &.open {
    //height: 318px;
    height: 100vh;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  }
  &.fixed {
    position: fixed;
  }
}
@media screen and (min-width: 768px) {
  #navigation-bar-wrapper {
    height: 80px;
    #company-logo {
      width: 144px;
      height: 56px;
    }
    #burger-menu-icon {
      display: none;
    }
    #navigation-bar-links-wrapper {
      display: block;
    }
    &.slide-out {
      top: -80px;
    }
  }
  .navigation-bar-links {
    flex-direction: row;
    margin: 0;
    padding: 0;
    li {
      height: auto;
      padding: 0;
      line-height: initial;
      background-color: transparent !important;
      a {
        min-width: 85px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        color: #52A23E;
        &:hover {
          color: #428232 !important;
        }
      }
      &.get-funded-button {
        margin-top: 0;
        width: 140px;
        height: 56px;
        a {
          padding: 2.5px 0;
          .button {
            height: 51px;
            line-height: 49px;
          }
        }
      }
    }
  }
  #navigation-bar-dropdown {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .navigation-bar-links {
    li {
      a {
        min-width: 120px;
      }
      &.get-funded-button {
        width: 160px;
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .navigation-bar-links {
    li {
      a {
        min-width: 140px;
      }
    }
  }
}
