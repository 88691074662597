@import 'node_modules/normalize.css/normalize';

@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import 'node_modules/iconfonts/stylesheets/font-awesome';

@include fa-font();

@import 'node_modules/iconfonts/stylesheets/ionicons';

@include ion-font();

// General scss
@import './theme.scss';
@import './buttons.scss';
@import './reuseable.scss';
@import './icons.scss';

// Modules scss
@import '../_modules/nav/nav';
@import '../_modules/hero/hero';
@import '../_modules/what-we-do/what-we-do';
@import '../_modules/portfolio/portfolio';
@import '../_modules/team/team';
@import '../_modules/news/news';
@import '../_modules/blog/blog';
@import '../_modules/careers/careers';
@import '../_modules/footer/footer';
@import '../legal/legal';


/*-----------------------------------------------------------------------------------*/
/*      InReach - v3
/*-----------------------------------------------------------------------------------*/
html, body {
  margin: 0;
  padding: 0;
  //background:#FFF;
  width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.disable-scroll {
    overflow: hidden;
  }
}

/* Prevent ugly blue glow on chrome and safari */
a, button {
  outline: none !important;
}

::-moz-selection { /* Code for Firefox */
  color: #ffffff;
  background: rgba(90,170,70,1);
}

::selection {
  color: #ffffff;
  background: rgba(90,170,70,1);
}

/* Background Effects */
.b-parallax {
  background-attachment: fixed;
}

* {
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

#back-to-top {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: $primary;
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  z-index: 1;
  &.show-button {
    opacity: 1;
  }
  .scroll-up-icon {
    color: #ffffff;
    font-size: 12px;
    height: 12px;
    line-height: 10px;
    &:before {
      @include fa-icon('chevron-up')
    }
  }
}

/* Globals type styling */
h1, h2, h3, h4, h5, h6, label, .btn, a {
  font-family: Roboto, 'sans-serif';
}
p {
  font-family: Open Sans, 'sans-serif';
  font-weight: 400;
  font-size: 16px;
}
a {
  color: $primary;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: $secondary;
    text-decoration: none;
  }
}
h1 {
  font-size: 24px;
  color: $primary;
  line-height: 30px;
}
h2 {
  font-size: 20px;
  color: $greyOne;
  line-height: 26px;
}
h3 {
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  margin: 0;
  &.section-title {
    text-align: center;
    margin: 0 24px 6px;
  }
}
h4 {
  font-size: 20px;
  color: $greyOne;
  line-height: 26px;
  &.section-subtitle {
    text-align: center;
    margin: 0 24px;
  }
}
h5 {
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  margin: 0;
}
h6 {
  font-size: 20px;
  color: $greyOne;
  line-height: 26px;
}

.section {
  max-width: 1280px;
  margin: auto;
  padding: 0;
  &.full-width {
    max-width: none;
  }
  .items-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    .item {
      flex-grow: 1;
      margin: 24px 24px 0;
      .item-content {
        padding: 24px 12px 0;
        h5 {
          text-align: left;
          margin: 0 0 6px;
        }
        h6 {
          text-align: left;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  /* Globals type styling */
  h1 {
    font-size: 42px;
    line-height: 48px;
  }
  h2 {
    font-size: 28px;
    line-height: 34px;
  }
  h3 {
    font-size: 28px;
    line-height: 34px;
  }
  h4 {
    font-size: 22px;
    line-height: 28px;
  }
  h5 {
    font-size: 28px;
    line-height: 34px;
  }
  h6 {
    font-size: 22px;
    line-height: 28px;
  }
  p {
    font-size: 18px;
    color: $greyOne;
  }

  .section {
    padding: 72px 0 0;
    .items-wrapper {
      .item {
        margin: 24px;
        .item-content {
          padding: 24px 12px;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  /* Globals type styling */
  h1 {
    font-size: 42px;
    line-height: 48px;
  }
  h2 {
    font-size: 28px;
    line-height: 34px;
  }
  h3 {
    font-size: 28px;
    line-height: 34px;
  }
  h4 {
    font-size: 22px;
    line-height: 28px;
  }
  h5 {
    font-size: 28px;
    line-height: 34px;
  }
  h6 {
    font-size: 22px;
    line-height: 28px;
  }
  p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1280px) {
  h3 {
    &.section-title {
      font-size: 36px;
      text-align: center;
      margin: 0 24px 12px;
      //font-weight: bold;
    }
  }
  h4 {
    &.section-subtitle {
      font-size: 24px;
      text-align: center;
    }
  }
}
