.content-break {
  height: 1px;
  width: 60px;
  background-color: $greyTwo;
  margin: 24px auto;
  &.white {
    background-color: #ffffff;
  }
}

.show-more {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80px;
  cursor: pointer;
  color: $primary;
  span {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    &.chevron-down {
      margin: -8px auto 0;
      font-size: 20px;
      &:before {
        @include fa-icon('caret-down');
      }
    }
  }
  &:hover {
    color: $secondary;
  }
}
.tablet1 {
  display: none;
}
.tablet2 {
  display: none;
}
.desktop {
  display: none;
}
.mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .content-break {
    height: 2px;
    width: 120px;
    margin: 48px auto;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: none;
  }
  .tablet2 {
    display: none;
  }
  .tablet1 {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .content-break {
    height: 2px;
    width: 120px;
    margin: 48px auto;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: none;
  }
  .tablet1 {
    display: none;
  }
  .tablet2 {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .mobile {
    display: none;
  }
  .tablet1 {
    display: none;
  }
  .tablet2 {
    display: none;
  }
  .desktop {
    display: block;
  }
}
