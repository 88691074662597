#blog {
  padding: 0 0 48px;
  .content-break {
    margin: 48px auto 24px;
  }
  .items-wrapper {
    //max-height: 420px;
    flex-direction: column;
    .item {
      .image {
        width: 100%;
        padding-top: 65%;
        background-size: cover;
        background-position: center;
      }
      .item-content {
        h5 {
          font-size: 20px;
          &:hover {
            color: $secondary;
          }
        }
        p {
          margin-bottom: 24px;
        }
        span {
          color: $greyOne;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding: 72px 0 48px;
    .items-wrapper {
      .item {
        //width: 29%;
        .item-content {
          padding: 0 12px;
          h5 {
            font-size: 24px;
          }
        }
      }
    }
  }
}
