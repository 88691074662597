.page-container.legal {
  padding: 12px;
  width: 100%;
  h1 {
    margin: 48px 0;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  h2, p {
    color: $black !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  h2 {
    font-size: 24px;
    margin: 24px 0 12px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  b {
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding-left: 12px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    li {
      margin-bottom: 8px;
    }
  }
  table {
    margin: 12px auto;
    width: 100%;
  }
  th, td {
    padding: 4px;
    border: 1px solid black;
  }
  &.legal {
    padding: 24px;
    a {
      font-size: 16px;
    }
  }
  .footer {
    font-size: 12px;
    margin-top: 48px;
    color: $black;
  }
  @media screen and (min-width: 768px) {
    padding: 0 48px 48px;
    h1 {
      font-size: 32px;
    }
  }
}


