.button {
  border-radius: 28px;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  height: 48px;
  line-height: 46px;
  font-size: 16px;
  &.green {
    background-color: $primary;
    color: #ffffff;
    border: 2px solid $primary;
  }
  &.white {
    background-color: #ffffff;
    color: $primary;
    border: 2px solid #ffffff;
  }
}
@media screen and (min-width: 768px) {
  .button {
    cursor: pointer;
    height: 51px;
    line-height: 49px;
    &.green {
      background-color: transparent;
      color: $primary;
      &:hover {
        background-color: $primary;
        color: #ffffff;
      }
    }
    &.white {
      background-color: transparent;
      color: #ffffff;
      border: 2px solid #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $greyOne;
      }
    }
  }
}
