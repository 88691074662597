#portfolio {
  padding: 48px 0 0;
  .items-wrapper {
    .item {
      .image {
        width: 100%;
        height: auto;
        padding-top: 65%;
        background-size: cover;
        background-position: top center;
      }
      .item-content {
        .location {
          color: $greyOne;
          margin: 0 0 6px;
          &:before {
            @include ion-icon('location')
          }
        }
        .acquired-by {
          color: $greyOne;
          margin: 6px 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          a {
            color: $greyOne;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            .acquired-by-logo.no-logo {
              width: 0;
              height: 0;
            }
            .acquired-by-logo {
              width: 18px;
              height: 18px;
              margin: 0 4px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              display: inline-block;
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .button {
    margin: 48px 24px 0;
  }
  .content-break {
    margin: 48px auto;
  }
  @media screen and (min-width: 768px) {
    padding: 72px 0 0;
    .items-wrapper {
      .item {
        width: 35%;
      }
    }
    .button {
      width: 280px;
      margin: 12px auto 0;
    }
  }
  @media screen and (min-width: 1280px) {
    .items-wrapper {
      .item {
        display: flex;
        flex-direction: row;
        margin: 24px 24px 60px;
        .image {
          padding-top: 0;
          width: 200px;
          height: 200px;
          flex-shrink: 0;
          order: 2;
        }
        .item-content {
          margin-right: 24px;
          padding: 0;
          order: 1;
          h5 {
            text-align: left;
            margin: 0;
          }
          h6 {
            text-align: left;
            margin: 0;
          }
          .location {
            margin: 0;
          }
          p {
            color: $greyOne;
            margin: 6px 0 0;
            font-size: 16px;
          }
        }
      }
    }
  }
}
